import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Box } from "ticeboxes"
import { H1, H2 } from "../components/general/Typography"
import Layout from "../components/layout/Layout"
import { ServicesQuery } from "../../graphql-types"
import { Container, Row, Col } from "reactstrap"
import CardList from "../components/general/CardList"
import Head from "../components/layout/Head"

interface Props {}

const servicesQuery = graphql`
  query Services {
    allMarkdownRemark(filter: { fields: { type: { eq: "_service" } } }) {
      edges {
        node {
          frontmatter {
            title
            background_image
            is_active
            override_link
            image_is_text
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

const service: React.FC<Props> = () => {
  const data: ServicesQuery = useStaticQuery(servicesQuery)
  return (
    <>
      <Head
        title="Service"
        description="Vind de verschillende services aangeboden bij Eyefashion By Rami."
      ></Head>
      <Layout>
        <Container>
          <Row>
            <Col xs="12">
              <Box text-center my="5">
                <H1>Service</H1>
                <H2>Bij Eyefashion by Rami</H2>
              </Box>
              <Box my="5">
                <CardList data={data} category="service" />
              </Box>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default service
